import React from "react";
import { Container } from "@atoms";
import { NextArrow } from "@molecules";
import loadable from "@loadable/component";

const Map = loadable(() => import("@organisms/Map"));
const Stats = loadable(() => import("@organisms/Stats"));

const Grants = ({ world, map }) => {
  return (
    <div className="texture-pattern relative z-[100] overflow-hidden bg-viridian">
      <Container className="pt-24" frame>
        <Stats stats={world.stats} />
        <div className="h-16" />
        <NextArrow />
        <div className="h-48" />
        <Map {...map} />
      </Container>
    </div>
  );
};

export default Grants;
